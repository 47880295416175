@import '../../shared/styles/global.css';

.user-detail {
	max-width: 1120px;
	width: 80%;
}

.user-footer {
	justify-content: center;
}

.user-profile {
	color: var(--darkerBlack);
	text-align: left;
	font: normal normal normal 28px/36px Poppins-Regular;
	letter-spacing: 0;
	opacity: 1;
}

.user-verified {
	font: italic normal normal 16px/24px Poppins-Regular;
	text-align: left;
	letter-spacing: 0.5px;
	color: #2e8000de;
	opacity: 1;
	margin-left: 18px;
}

.user-not-verified {
	text-align: left;
	font: italic normal normal 16px/24px Poppins-Regular;
	letter-spacing: 0.5px;
	color: #f81c2c;
	opacity: 1;
}

.user-body {
	padding: 34px 27px;
}

.otp-config {
	font: normal normal normal 16px/24px Poppins-Regular;
	letter-spacing: 0.5px;
	color: var(--darkerBlack);
	opacity: 1;
}

.otp-switch > input[type='checkbox']:checked,
.otp-switch > .custom-control-input:active {
	background-color: var(--orange) !important;
	border-color: var(--orange) !important;
}

.otp-switch > .custom-control-input:checked ~ .custom-control-label::before {
	background-color: var(--paleOrange) !important;
	border-color: var(--paleOrange) !important;
}

.otp-switch > .custom-control-input:checked ~ .custom-control-label::after {
	background-color: var(--orange) !important;
	border-color: var(--orange) !important;
}

.otp-switch .custom-control-label::after {
	background-color: var(--orange) !important;
}

.otp-switch-view .custom-control-input:checked ~ .custom-control-label::before {
	background-color: var(--paleOrange) !important;
	border-color: var(--paleOrange) !important;
}

.user-btn {
	border-color: var(--orange);
	background-color: var(--white);
	font: normal normal normal 14px/21px Poppins-Regular;
	color: var(--orange);
	width: 170px;
	height: 42px;
	margin: 0px 7px;
}
.user-btn:hover,
.user-btn:active,
.user-btn:not(:disabled):not(.disabled):active,
.user-btn:not(:disabled):not(.disabled).active,
.user-btn:focus {
	color: var(--white);
	border-color: var(--orange);
	background-color: var(--orange);
}

.user-btn:disabled {
	color: var(--whiteOrange);
	border-color: var(--whiteOrange);
	background-color: var(--white);
}

.user-btn.block-btn {
	border-color: var(--dangerRed);
	background-color: var(--dangerRed);
	color: var(--white);
}

.user-btn.block-btn:hover {
	background-color: var(--dangerRedHover);
}

.user-btn.unblock-btn {
	border-color: #14bb02;
	background-color: #14bb02;
	color: var(--white);
}

.user-btn.close-btn {
	background-color: var(--orange);
	border-color: var(--orange);
	color: var(--white);
}

.user-btn.close-btn:hover {
	background-color: var(--darkerOrange);
	border-color: var(--darkerOrange);
	color: var(--white);
}

.user-logs {
	margin-top: 20px;
	max-width: 1019px;
}

.user-logs > .log-header {
	text-align: left;
	font: normal normal normal 20px/22px Poppins-Regular;
	letter-spacing: 0.15px;
	color: var(--darkerBlack);
	opacity: 1;
}

.input-invalid {
	color: var(--dangerRed);
	font-size: 10px;
}

.input-invalid input {
	border-color: red !important;
}

div.view-user-modal-datepicker-div {
	position: relative;
	margin: 5px 10px;
}

div.view-user-modal-datepicker-div input {
	border: 1px solid #dadce0;
	padding: 15px;
	border-radius: 4px;
	width: 100%;
	height: 46px;
}

div.view-user-modal-datepicker-div span.placeholder,
.branch-lbl {
	position: absolute;
	margin: 13px 0;
	position: absolute;
	margin: 13px 0;
	padding: 0 4px;
	color: #6c757d;
	display: flex;
	align-items: center;
	font-size: 12px;
	top: 0;
	left: 17px;
	transition: all 0.2s;
	transform-origin: 0% 0%;
	background: none;
	pointer-events: none;
}

div.view-user-modal-datepicker-div > div.react-datepicker-wrapper {
	width: 100%;
}

div.view-user-modal-datepicker-div input:valid + span.placeholder,
div.view-user-modal-datepicker-div input:focus + span.placeholder,
div.view-user-modal-datepicker-div input:disabled + span.placeholder {
	transform: scale(0.75) translateY(-28px);
	background: #fff;
}

div.view-user-modal-datepicker-div input:valid,
div.view-user-modal-datepicker-div input:focus {
	border-color: black;
}

div.view-user-modal-datepicker-div input:focus {
	color: #284b63;
	border-color: #284b63;
}

div.view-user-modal-datepicker-div input:focus + span.placeholder {
	color: #284b63;
}

.hidden {
	color: #d93025;
	font-size: 0.7rem;
}

span.hidden svg {
	position: relative;
	bottom: 2px;
	margin-right: 5px;
}

form.input-block-height .row {
	height: 80px;
}

/*.otp-switch .custom-control-label:after {*/
/*    top: calc(.25rem);*/
/*    left: calc(-2.25rem);*/
/*    width: calc(1rem - 0px);*/
/*    height: calc(1rem - 0px);*/
/*}*/

.mobile-input-group:focus-within {
	border-color: #0176c0;
}

div > .edit-user-alert {
	box-shadow: 0px 8px 10px #00000033;
	border-radius: 4px;
	opacity: 0.9;
	text-align: center;
	width: 100%;
	z-index: 999;
	color: #ffffff;
	position: absolute;
	top: 20px;
	flex: 0 1 auto;
	margin: 0 auto;
}

div > .edit-user-alert#alert-success {
	background: #198754 0% 0% no-repeat padding-box;
}

div > .edit-user-alert#alert-error {
	background: #f81c2c 0% 0% no-repeat padding-box;
}

.placeholder.date-lbl,
.placeholder.branch-lbl {
	transform: scale(0.75) translateY(-28px);
	background: #fff !important;
}

div.branch-select__input > input {
	height: 20px;
	min-height: 20px;
}

.multiselectContainer {
	padding: 0 10px 0 10px;
}

input#username.form-control:disabled,
input#dateCreated.form-control:disabled,
input#userType.form-control:disabled,
input#branchName.form-control:disabled,
select#userRole.form-control:disabled,
input#userRole.form-control:disabled,
input#firstname.form-control:disabled,
input#middlename.form-control:disabled,
input#lastname.form-control:disabled,
input#userStatus.form-control:disabled,
input#contactNumber.form-control:disabled,
input#emailAddress.form-control:disabled,
input#jobTitle.form-control:disabled,
input#division.form-control:disabled,
input#department.form-control:disabled,
div.view-user-modal-datepicker-div input:disabled {
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
}

ul.optionContainer {
	max-height: 450px !important;
}

ul.optionContainer :hover {
	color: rgb(202, 224, 231);
}

.paginationContainer {
	justify-content: flex-end;
	margin-top: 2vh;
}
.paginationContainer .page-item > * {
	color: var(--orange) !important;
}

.paginationContainer .page-item.active > * {
	background-color: var(--orange) !important;
	border-color: var(--orange) !important;
	color: white !important;
}

.roleSpinnerContainer {
	display: flex;
	justify-content: center;
}

.table-main {
	min-width: 400px;
}

.card {
	min-width: 595px !important
}
