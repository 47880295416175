.swal-modal {
    background: white;
    border-radius: 4px;
    box-shadow: 0px 2px 5px #0000001A;
    width: 400px;
}

.swal-text:first-child {
    margin-top: 30px;
}

.swal-text {
    color: #000000;
}

.swal-footer {
    text-align: center;
    padding: 30px 30px;
    margin-top: 0px;
}

.swal-button-container {
    width: 100%;
    margin: 0;
}

.swal-button {
    color: #FFFFFF;
    background: #F26122;
    width: 100%;
}

.swal-button:not([disabled]):hover {
    background: #F26122;
}
