.modal-batch-header {
  height: fit-content;
}

.modal-batch-header > div {
  flex: 1 1 auto;
  padding-left: 2rem;
}
.modal-batch-body {
  height: 400px;
  align-items: center;
  overflow-y: auto;
}

.modal-batch-main {
  flex: 1 1 auto;
  padding-left: 7rem;
}

.modal-batch-footer {
  display: flex;
  width: 68%;
  padding: 0.75rem;
  border-top: 0px;
}

.rowData {
  padding-left: 10px;
  width: 100px;
}
.rowDetails {
  width: 350px;
}

.headerRow {
  width: 100px;
  height: 30px;
  font: normal normal bold 14px/25px Poppins-Medium;
  border-bottom: 0px;
  padding-left: 5px;
}
.headerDetails {
  width: 350px;
  height: 30px;
  font: normal normal bold 14px/25px Poppins-Medium;
  border-bottom: 0px;
}
.div-header {
  font-weight: bold;
  padding-bottom: 10px;
  font: normal normal bold 16px/25px Poppins-Medium;
}
.div-message {
  padding-bottom: 20px;
  font: normal normal 14px/25px Poppins-Medium;
}

.file-error-batch {
  font: normal normal 12px/25px Poppins-Medium;
  color: red;
  padding-left: 10px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.fileInput {
  display: flex;
}

.fileInput #disabled-input-id {
  background-color: #0000000c;
  border: 2px;
  width: 600px;
  border-radius: 5px;
  height: 42px;
  margin-top: 8px;
  padding-left: 15px;
  font: normal normal 12px/25px Poppins-Medium;
}

.fileInput #disabled-input-id-err {
  background-color: #0000000c;
  border: solid;
  border-width: 2px;
  border-color: #ff030383;
  width: 600px;
  border-radius: 5px;
  height: 42px;
  margin-top: 8px;
  padding-left: 15px;
  font: normal normal 12px/25px Poppins-Medium;
}

.fileInput :global(.slds-input-has-icon .slds-spinner_container *::before),
.fileInput :global(.slds-input-has-icon .slds-spinner_container *::after) {
  background-color: var(--orange);
}

.fileInput input {
  pointer-events: none;
}

.browse-btn {
  color: #00000096;
  background: #ffffff;
  width: 140px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid#000000;
  font: normal normal 14px/25px Poppins-Medium;
}
