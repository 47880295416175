.search-grp {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    place-items: center;
    padding: 0 25px;
    margin-top: 28px;
    height: 36px;
}

.search-grp-text {
    text-align: left;
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: 0.43px;
    color: #3B3B3B;
    opacity: 1;
    vertical-align: center;
    margin-right: 14px;
}

#grant-ddl-toggle.dropdown-toggle {
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    opacity: 1;
}

#grant-item.dropdown-item {
    padding-top: 8px;
    border-radius: 0;
}

#grant-ddl-menu.dropdown-menu.show {
    padding: 0;
}

#grant-ddl-toggle.dropdown-toggle,
#grant-ddl-menu.dropdown-menu.show,
#grant-item.dropdown-item,
.search-container,
#grant-ddl-toggle.btn-primary:active,
#grant-ddl-toggle.btn-primary:focus,
#grant-ddl-toggle.btn-primary:hover,
#grant-ddl-toggle.show > .btn-primary.dropdown-toggle {
    height: 36px;
    width: 160px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: left;
    font: normal normal 300 12px/18px Poppins;
    letter-spacing: 0px;
    color: #3B3B3B;
}

#grant-ddl-toggle.dropdown-toggle,
#grant-ddl-menu.dropdown-menu.show,
.search-container {
    border-radius: 4px;
}

#grant-ddl-toggle.dropdown-toggle.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:focus {
    box-shadow: 0 0 0 0 #ffffff;
}

#grant-ddl-toggle.dropdown-toggle::after {
    display: none !important;
}

.search-container {
    margin-left: 14px;
}

.search-container {
    height: 36px;
    width: 356px;
    display: flex;
    flex-direction: row;
}

#search-input-group-text.input-group-text {
    height: 36px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #C4C4C4;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

#search-input-group.input-group > .form-control {
    height: 36px;
    border-radius: 0 4px 4px 0;
    border-top: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    border-left: #FFFFFF;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: left;
    font: normal normal 300 12px/18px Poppins;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
    padding-left: 0;
}

#search-input-group.input-group > .form-control:focus {
    box-shadow: 0 0 0 0 #ffffff !important;
}

.search-icon {
    transform: rotateY(180deg);
}

.button#grant-ddl-toggle {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    opacity: 1;
}

.add-btn-container {
    margin-left: auto;
}

.btn#add-btn {
    background: #F26122 0 0 no-repeat padding-box;
    border: 1px solid #F26122;
    border-radius: 4px;
    opacity: 1;
    font: normal normal normal 11px/17px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 120px;
    height: 36px;
    text-align: center;

}

.btn#add-btn:hover,
.btn#add-btn:active,
.btn#add-btn:focus {
    background: #E1571C 0 0 no-repeat padding-box;
    border: 1px solid #E1571C;
    border-radius: 4px;
    opacity: 1;
}

/*Table CSS*/
#platform-table.table-heading {
    background: #0176C0 0 0 no-repeat padding-box;
    border-radius: 4px 4px 0 0;
    opacity: 1;
    font: normal normal 600 14px/19px Wavehaus;
    letter-spacing: 0;
    color: #FFFFFF;
}

#platform-table.tbody {
    text-align: left;
    font: normal normal normal 14px/46px Poppins;
    letter-spacing: 0;
    color: #3B3B3B;
    opacity: 1;
}

