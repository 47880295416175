.session-btn,
.session-btn:hover {
  min-width: 140px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #f26122;
}

.session-btn.cancel {
  color: #ffffff;
  background: #f26122;
}
.session-btn.cancel:active,
.session-btn.cancel:hover {
  color: #f26122;
  background: #ffffff;
}

.session-btn.add {
  color: #ffffff;
  background: #f26122;
}

.session-btn:active,
.session-btn:hover {
  color: #ffffff;
  background: #e1571c;
}

#access-denied-modal {
  width: 420px;
}