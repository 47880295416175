div#view-all-branch > div.modal-content {
	max-height: 188% !important;
	max-width: 100% !important;
}
div#view-all-branch.modal-dialog {
	max-width: 45%;
}
table#platforms-table.table-striped > tbody > tr:nth-child(odd) {
	background-color: #e6f5fe;
}
table#platforms-table.table-striped > tbody > tr:hover {
	cursor: pointer;
}

button.btn-branch-nav {
	background: #ffffff 0 0 no-repeat padding-box;
	border: 1px solid grey;
	border-radius: 0;
	color: #f26122;
}

button.btn-nav-word {
	background: #ffffff 0 0 no-repeat padding-box;
	border: 1px solid grey;
	color: #f26122;
}

button.btn-nav-word:hover,
button.btn-nav-word:active,
button.btn-branch-nav:hover,
button.btn-branch-nav.btn-primary:not(:disabled):not(.disabled).active,
button.btn-nav-word:focus,
button.btn-branch-nav:focus {
	background: #f16124 0 0 no-repeat padding-box;
	border: 1px solid grey;
	color: #ffffff;
	box-shadow: none;
}
button.btn-nav-word:disabled {
	background: #ffffff 0 0 no-repeat padding-box;
	border: 1px solid grey;
	color: #f26122;
}

.branch-search-icon {
	transform: rotateY(180deg);
}
