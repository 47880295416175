.session-btn,
.session-btn:hover {
    min-width: 140px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #F26122;
}

.session-btn.cancel {
    color: #F26122;
    background: #FFFFFF;
}
.session-btn.cancel:active,
.session-btn.cancel:hover {
    color: #FFFFFF;
    background: #F26122;
}

.session-btn.add {
    color: #FFFFFF;
    background: #F26122;
}

.session-btn:active,
.session-btn:hover {
    color: #FFFFFF;
    background: #E1571C;
}
