/*********************************
    Floating Label CSS
 ********************************/
div.input-block {
    position: relative;
    margin: 5px 10px;
}

div.input-block input,
div.input-block select
{
    border: 1px solid #DADCE0;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    height: 46px;
}
div.multiselect-block multiselect
{
    border: 1px solid #DADCE0;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    height: 46px;
}

div.multiselect-block select
{
    border: 1px solid #DADCE0;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    height: 46px;
}

div.input-block span.placeholder {
    position: absolute;
    margin: 13px 0;
    padding: 0 4px;
    color: #6c757d;
    display: flex;
    align-items: center;
    font-size: 12px;
    top: 0;
    left: 17px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    background: none;
    pointer-events: none;
}
div.multiselect-block span.placeholder {
    position: absolute;
    margin: 13px 0;
    padding: 0 4px;
    color: #6c757d;
    display: flex;
    align-items: center;
    font-size: 12px;
    top: -25px;
    left: 17px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    background: none;
    pointer-events: none;
}

div.input-block input:valid+span.placeholder,
div.input-block input:focus+span.placeholder,
div.input-block input:disabled+span.placeholder,
div.input-block select:disabled+span.placeholder,
div.input-block select:valid+span.placeholder,
div.multiselect-block select:valid+span.placeholder,
div.multiselect-block select:disabled+span.placeholder,
div.input-block select:focus+span.placeholder {
    transform: scale(.75) translateY(-28px);
    background: #fff;
}

div.input-block input:valid,
div.input-block input:focus,
div.input-block select:valid,
div.input-block select:focus {
    border-color: black;
}

div.input-block input:focus,
div.input-block select:focus {
    color: #284B63;
    border-color: #284B63;
}

div.input-block input:focus+span.placeholder,
div.input-block select:focus+span.placeholder {
    color: #284B63;
}
