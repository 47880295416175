.avatar {
    width: 36px;
    height: 36px;
}

.nametag {
    width: fit-content;
    height: 36px;
    float: right;
    margin-top: 24px;
    text-align: right;
    display: flex;
    flex-direction: row;
}

.profile-name {
    margin-top: 5px;
    margin-bottom: auto;
    margin-left: 12px;
    text-decoration: none;
    font: normal normal bold 12px/24px Poppins-Regular;
    letter-spacing: 0.11px;
    color: #212121;
    opacity: 1;
}

.profile-name .dropdown-content {
    display: none;
    position: relative;
    border-radius: 4px;
    background: white;
    width: 120px;
    height: 50px;
    text-align: left;
    padding: 10px;
    box-shadow: 5px 10px 18px #888888;
    z-index:1000;
  }

.profile-name:hover > .dropdown-content {
    display: block;    
    position: relative;
}

.logOutButton{
    font-size: 13px;
    color:#888888;
    padding: 0;
    border: none;
    background: none;
}
.logOutButton:active:focus {
    background: none !important;
}
.logOutButton:hover{
    color:orange;
    border: none;
    background: none;
}

