.add-role-modal {
    height: 610px;
}

.permission-list {
    height: 320px;
    overflow-y: auto;
}

.permission-module-list {
    margin-left: 20px;
    /*padding-top: 5px;*/
}

.permission-module {
    padding: 20px 0px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 0.5px solid #707070;
}

.permission-module:last-child {
    padding: 20px 0px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: none !important;
}


.permission-group {
    margin-bottom: 10px;
}

.permission-module-item {
    padding: 10px 0px;
    margin-left: 35px;
}

input[type=checkbox]:checked {
    background-color: #F26122;
    border-color: #F26122;
}

input.isError {
    border: 1px solid red !important;
    color: red !important;
}

input.isError + span {
    color: red !important;
}


/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #EBEBEB;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #989898;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.expandCollapseLink {
    margin-left: 0;
}