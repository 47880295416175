button.view-role {
    width: 140px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #F26122;
}

h3.modal-title {
    padding: 5px 20px;
}

.btn-no-fill {
    color: #F26122;
    background: #FFFFFF;
    border-color: #F26122;
}

.btn-fill {
    color: #FFFFFF;
    background: #F26122;
}

.btn-delete {
    color: #FFFFFF;
    background: #F81C2C;
}

.form-check-input:checked{
    color: #FFFFFF;
    background: #F26122;
    border-color: #F26122;
}

.role-switch .custom-control-input:checked~.custom-control-label::before{
    color: #FFFFFF;
    background: #F26122;
    border-color: #F26122;
}

.role-switch .custom-control-input:disabled:checked~.custom-control-label::before{
    color: #FFFFFF;
    background: #F26122;
    border-color: #F26122;
    opacity: 0.5;
}

button.view-role:disabled {
    opacity: 0.7;
}

.checkbox-holder {
    justify-content: left;
}

.checkbox-holder > .form-check {
    text-align: left;
}

.checkbox-holder > .form-check > .form-check-label {
    opacity: 1 !important;
    color: #212529 !important;
}

.roleName .form-control:disabled, .form-control:read-only {
    background-color: #fff;
}

.permission-list {
    height: 350px;
    overflow-y: auto;
}

.expandCollapseLink {
    margin-left: 45px;
    color: #F26122;
    cursor: pointer;
}

.expandIcon,
.collapseIcon {
    width: 15px;
    height: 15px;
    margin-left: 10px;
}