.my-pagination > .pagination > .page-item > span.page-link {
    background-color: #F26122;
    border-color: #F26122;
    color: #ffffff;
}

.my-pagination > .pagination > .page-item > a.page-link {
    background-color: #ffffff;
    color: #F26122;
}
