div.react-datepicker__header.react-datepicker__header--custom {
    background-color: #fff !important;
    border-bottom: 0px !important;
}

input.role-list-filter-inputs.form-control {
    height: 36px;
    border-radius: 0 4px 4px 0;
    border-top: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    border-left: #FFFFFF;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: left;
    font: normal normal lighter 12px/18px Poppins-Regular;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
    padding-left: 0;
}

.search-button-role-list {
    height: 36px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #C4C4C4;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

input.form-control.role-list-datepicker {
    height: 36px;
    border: 1px solid #C4C4C4;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: left;
    font: normal normal 300 12px/18px Poppins-Regular;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
}

.role-list-download-btn {
    opacity: 1;
    font: normal normal normal 11px/17px Poppins-Regular;
    letter-spacing: 0px;
    line-height: 20px;
    color: #FFFFFF;
    width: 120px;
    height: 36px;
    text-align: center;
    align-self: flex-end;
}