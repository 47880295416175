.alerts {
    position: absolute;
    text-align: center;
    opacity: 0.9;
    top: 110px;
    width: 100%;
    z-index: 999;
}

.alert {
    width: 80%;
    box-shadow: 0px 8px 10px #00000033;
}

.alerts-success {
    background: #198754;
    color: #FFFFFF;
}

.alerts-warning {
    background: #F26122;
    color: #FFFFFF;
}

.alerts-failed {
    background: #F81C2C;
    color: #FFFFFF;
}
