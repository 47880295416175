:root {
  /* colors */
  --headerBlue: #0077c0;
  --black: #3F4040;
  --lightGray: #F6F5F7;
  --white: #ffffff;
  --gray: #626262;
  --orange: #F26122;
  --darkerOrange: #E1571C;
  --darkerBlack: #000000DE;
  --paleOrange: #F2612261;
  --whiteOrange: #F6A07B;
  --dangerRed: #F81C2C;
  --dangerRedHover: #DF0818;

  /* fonts */
  --common-font: Wavehaus-Regular;

  background-color: var(--lightGray);
  font: var(--common-font);

}

@font-face {
  font-family: Wavehaus-Regular;
  src: url('fonts/Wavehaus-95SemiBold.otf');
}

@font-face {
  font-family: Poppins-Regular;
  src: url('fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('fonts/Poppins-Medium.ttf');
}