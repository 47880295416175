@import "../../shared/styles/global.css";

.home {
    text-align: center;
    margin-top: 137px;
}

.pageTitle {
    color: var(--headerBlue);
    font: normal normal 600 32px/56px Wavehaus-Regular;

}

.pageSubTitle {
    font: normal normal normal 18px/24px Poppins-Medium;
    margin-bottom: 50px;
    margin-top: 10px;
}

.text {
    color: var(--black);
    font: normal normal bold 12px/14px Poppins-Medium;
    text-align: center;
}

.lbl-platform {
    margin-top: 5px;
}

.btns {
    background: var(--white) 0% 0% no-repeat padding-box;
    color: var(--black);
    box-shadow: 0px 2px 5px #0000001A;
    border: 0px;
}

.btnMng {
    height: 200px;
    width: 200px;
    margin: 10px;
}

.btnMng:disabled {
    background-color: var(--white);
}

.btnImg {
    height: 120px;
    width: 120px;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}


#sysIntegBtn {
    height: 43px;
    width: 200px;
    margin: 50px;
}

.brand {
    margin-top: 19px;
    margin-left: 29px;
}

.btnMng:hover {
    background-color: #fff !important;
    text-decoration: none;
}

#sysIntegBtn:hover {
    background-color: #fff !important;
    text-decoration: none;
}

.disabled-link {
    pointer-events: none;
}
