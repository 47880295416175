select.add-user-modal-select {
    font-size: 0.9rem;
}

div.add-user-modal-datepicker > div.react-datepicker-wrapper {
    display: block;
}

span.add-user-modal-placeholder {
    transform: scale(.75) translateY(-28px);
    background: #fff !important;
}

div.add-user-modal-datepicker select:valid {
    border-color: inherit !important;
}

div.add-user-modal-datepicker select {
    padding: .25rem 36px .25rem .5rem;
}

div.add-user-inputs input:focus, div.add-user-inputs select:focus {
    border: 1px solid #0077C0 !important;
    color: #3E3F42;
}

div.add-user-inputs select:focus+span.placeholder {
    color: #0077C0 !important;
}

div.custom-datepicker-error input {
    border: 1px solid #D93025;
}

div.custom-datepicker-error span.placeholder {
    color: #D93025;
}

div.add-user-modal-datepicker > div.react-datepicker__tab-loop+span.placeholder {
    transform: scale(.75) translateY(-28px) !important;
    background: #fff !important;
}

div.add-user-modal-datepicker > span.activeplaceholder {
    transform: scale(.75) translateY(-28px) !important;
    background: #fff !important;
}

div.add-user-inputs > span.activeplaceholder {
    transform: scale(.75) translateY(-28px) !important;
    background: #fff !important;
}

input.datepicker-error {
    border: 1px solid #D93025 !important;
}

div.branch-select__input > input {
    height: 20px;
    min-height: 20px;
}

div#select-branch-id.branch-select-error {
    border: 1px solid #D93025;
    border-radius: 4px;
}

div#select-branch-id.branch-select-active {
    border: 1px solid #0076C0;
    box-shadow: #0077C0;
    border-radius: 4px;
}

div#select-branch-id.branch-select {
    border: 1px solid #DADCE0;
    box-shadow: #0077C0;
    border-radius: 4px;
}
div#select-branch-id.branch-select-has-value {
    border: 1px solid #3E3F42;
    border-radius: 4px;
}

div.branch-select__value-container.branch-select__value-container--is-multi.branch-select__value-container--has-value {
    overflow: auto;
}

button.show-more-branches,
button.show-more-branches:hover {
    background: transparent;
    border: none;
    outline: none;
    color: #0077C0
}