.logo {
    width: 102px;
    height: 133px;
    margin: 10px 0;
}

.sidebar-main {
    margin: 5px;
    height: 100%;
    width: inherit;
    background: #ffff;
    display: flex;
    flex-direction: column;
    position: fixed;
    min-width: 135px
}

.welcome {
    margin-top: auto;
}

.sidebar-list {
    text-align: left;
    font: normal normal normal 14px/24px Poppins-Medium;
    letter-spacing: 0.43px;
    color: #0077C0;
    opacity: 1;
    height: auto;
    padding: 0;
    width: 100%;
    align-self: flex-start;
}

.sidebar-list .sidebar-item {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-list .sidebar-item:hover, li.sidebar-item.active {
    color: #0077C0
}

.sidebar-item #icon {
    flex: 20%;
    display: grid;
    place-items: center;
}

.sidebar-item #title {
    flex: 80%;
}

