.platform-btn {
    width: 140px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #F26122;
}

.platform-select {
    padding-top: 10px !important;
}

h3.modal-title {
    padding: 5px 20px;
}

.platform-btn-cancel {
    color: #F26122;
    background: #FFFFFF;;
}

.platform-btn-add {
    color: #FFFFFF;
    background: #F26122;
}

.platform-btn-add:disabled {
    opacity: 0.7;
}
