input.user-list-filter-inputs.form-control {
  height: 36px;
  border-radius: 0 4px 4px 0;
  border-top: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  border-left: #ffffff;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal 300 12px/18px Poppins;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  padding-left: 0;
}

.search-button-user-list {
  height: 36px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #c4c4c4;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal 300 12px/18px Poppins-Regular;
}

.user-list-download-btn {
  opacity: 1;
  font: normal normal normal 11px/17px Poppins-Regular;
  letter-spacing: 0px;
  line-height: 20px;
  color: #ffffff;
  width: 120px;
  height: 36px;
  text-align: center;
  align-self: flex-end;
}

.user-dropdown > button.dropdown-toggle,
.user-dropdown.show > .btn-primary.dropdown-toggle,
.user-dropdown > btn-primary:not(:disabled):not(.disabled):active {
  border-color: #c4c4c4;
  background-color: #fff;
  text-align: left;
  width: 150px;
  color: #212529;
  font: normal normal 300 12px/18px Poppins-Regular;
}

.user-dropdown > button.dropdown-toggle:active {
  border-color: #c4c4c4;
  background-color: #fff;
  text-align: left;
  width: 150px;
  color: #212529;
  font: normal normal 300 12px/18px Poppins-Regular;
}

.user-dropdown > button.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
  font: normal normal 300 12px/18px Poppins-Regular;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #f4edfc;
  color: black;
  text-align: center;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.reset-dropdown > button.dropdown-toggle,
.reset-dropdown.show > .btn-primary.dropdown-toggle,
.reset-dropdown > btn-primary:not(:disabled):not(.disabled):active {
  border-color: #c4c4c4;
  background-color: #fff;
  text-align: left;
  width: 600px;
  height: 56px;
  color: #212529;
  font: normal normal 300 16px/18px Poppins-Regular;
}

.reset-dropdown > div > a {
  border-color: #c4c4c4;
  background-color: #fff;
  text-align: left;
  width: 600px;
  height: 50px;
  color: #212529;
  padding-top: 10px;
  font: normal normal 300 16px/18px Poppins-Regular;
}

.reset-dropdown > button.dropdown-toggle:active {
  border-color: #c4c4c4;
  background-color: #fff;
  text-align: left;
  width: 600px;
  height: 56px;
  color: #ff7300;
  font: normal normal 300 16px/18px Poppins-Regular;
}
.reset-dropdown > button.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
  font: normal normal 300 16px/18px Poppins-Regular;
}
.reset-div {
  display: table;
  width: 720px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.reset-div > label {
  display: table-cell;
  width: 1px;
  white-space: nowrap;
  padding-right: 10px;
  font: normal normal 300 16px/18px Poppins-Regular;
}

.reset-div > .reset-dropdown {
  width: 70%;
}

.reset-message-div {
  font: normal normal 300 16px/18px Poppins-Regular;
}

.reset-footer {
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.reset-modal-content {
  display: flex;
  flex-direction: column;
  width: fit-content;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
