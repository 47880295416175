@import "../../shared/styles/global.css";


p {
    margin: 0px;
}

.modalMain {
    background-color: var(--white);
    padding: 24px;

    /* design for testing only; don't copy */
    width: 687px;
    height: 1412px;
    /* border: 1px solid var(--black); */
}


.modalTitle {
    font: normal normal 600 32px/56px Wavehaus-Regular;
    color: var(--orange);
    text-align: center;
    letter-spacing: 0px;
    opacity: 1;
}

.modalHeader1 {
    color: var(--headerBlue);
    font: normal normal 400 18px/24px Poppins-Medium;
    letter-spacing: 0.14px;
    opacity: 1;
    margin: 10px 0px;
}

.modalHeader2 {
    font: normal normal 400 16px/24px Poppins-Medium;
    letter-spacing: 0.12px;
    color: var(--headerBlue);
    opacity: 1;
    padding-bottom: 0px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.modalSection {
    font: normal normal normal 12px/24px Poppins-Medium;
    letter-spacing: 0.09px;
    color: var(--darkerBlack);
    opacity: 1
}

#registrationSection {
    text-align: center;
    text-indent: 50px;
    padding-left: 12px;
}

.newModalSection {
    margin-top: 30px;
}

.numberedItems {
    text-align: left;
    padding-left: 16px;
    margin-left: 10px;
}

.numBullet {
    vertical-align: text-top;
    padding-right: 5px;
}

.modalSubSection {
    text-align: left;
    padding-left: 50px;
    margin-left: 10px;
}

.policyTable {
    border-collapse: collapse;
    width: 100%;
    font: normal normal normal 12px/18px Poppins-Medium;
    letter-spacing: 0px;
}

.policyTable td, th {
    border-bottom: 1px solid var(--darkerBlack);
    line-height: normal;
    opacity: 1;
}

th.lar {
    border-bottom: none;
}

tr#lar {
    background-color: white;
}

#lar>th, th.lar {
    text-align: center;
}

#larTable td {
    text-align: center;
}

#larTable td:first-child {
    text-align: left;
}

#accessMatrix td, th {
    width: 50px;
    height: 55px;

}

#passwordMatrix td, #larTable td {
    padding: 15px 0px;
}


.policyTable tr:nth-child(even) {
    background-color: var(--lightGray);
}

#tdWider {
    width: 100%;
}

ul.dashed {
    list-style-type: none;
}
ul.dashed > li {
    text-indent: -5px;
}
ul.dashed > li:before {
    content: "-";
    text-indent: -5px;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover {
    opacity: .75;
}
