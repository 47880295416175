.spinner-logo {
  height: 51px;
  width: 51px;
  animation: spin 2s linear infinite;
}

.loading-body {
  height: 241px;
  display: flex;
  align-items: center;
}

.loading-body-batch {
  height: 240px;
  padding-top: 50px;
  align-items: center;
}

.loading-text {
  margin-top: 10px;
  text-align: center;
  font: normal normal 600 18px/21px Wavehaus-Regular;
  letter-spacing: 0px;
  color: #0076c0;
}

.loading-subtext {
  text-align: center;
  font: normal normal normal 14px/21px Poppins-Regular;
  letter-spacing: 0px;
  color: #3e3f42;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
