@import "../../shared/styles/global.css";

.audit-table th {
    text-align: left;
    font: normal normal bold 16px/25px Poppins-Medium;
    letter-spacing: 0px;
    color: var(--darkerBlack);
    opacity: 1;
}

.audit-table td {
    text-align: left;
    font: normal normal normal 16px/25px Poppins-Regular;
    letter-spacing: 0px;
    color: var(--darkerBlack);
    opacity: 1;
}
