.parent {
    height: inherit;
    margin: 0 0;
}

.row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

.sidebar {
    position: relative;
}

.main {
    margin: 5px 5px;
    padding: 5px 5px;
}

.pagination-disabled {
    color: #F26122;
}