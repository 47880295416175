@import "../../shared/styles/global.css";

#footer {
  margin-top: 36px;
}

#disclaimer {
  text-align: start;
  font: normal normal normal 10px/16px Poppins-Regular;
  letter-spacing: 0px;
  color: var(--gray);
  opacity: 1;
  width: inherit;
  margin-left: 29px;
}

#uamPolicy {
  text-align: end;
  font: normal normal normal 12px/18px Poppins-Regular;
  letter-spacing: 0px;
  color: var(--gray);
  width: auto;
  margin-right: 31px;
}

.policy-modal {
  width: 687px;
}