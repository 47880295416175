div#view-all-branch > div.modal-content {
    max-height: 188% !important;
    max-width: 100% !important;
}
div#view-all-branch.modal-dialog {
    max-width: 45%;
}
table#branches-table.table-striped>tbody>tr:nth-child(odd) {
    background-color: #E6F5FE;
}
table#branches-table.table-striped>tbody>tr:hover {
    cursor: pointer;
    color: #F26122
}

button.btn-branch-nav {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid grey;
    border-radius: 0;
    color: #F26122
}

button.btn-nav-word {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid grey;
    color: #F26122;
}

button.btn-nav-word:hover,
button.btn-nav-word:active,
button.btn-branch-nav:hover,
button.btn-branch-nav.btn-primary:not(:disabled):not(.disabled).active,
button.btn-nav-word:focus,
button.btn-branch-nav:focus {
    background: #F16124 0 0 no-repeat padding-box;
    border: 1px solid grey;
    color: #FFFFFF;
    box-shadow: none;
}
button.btn-nav-word:disabled {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid grey;
    color: #F26122;
}

.branch-search-icon {
    transform: rotateY(180deg);
}