textarea {
  resize: none;
}

.spiel {
  padding-bottom: 20px;
}

.form-control.is-invalid {
  background-image: none;
}
